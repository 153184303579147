import React, { useEffect, useState } from "react"
import "../assets/styles/global.css"

import HeaderHeroPage from "../components/Header/HeaderHomePage"
import FullPageLayout from "../layouts/FullPageLayout"
import Section from "../components/Section/Section"
import Solutions from "../components/Content/Solution/Solution"
import EbookComponent from "../components/Content/Ebooks/EbookComponent"
import Services from "../components/Content/Services"
import DelimiterSVG, { SVGDelimiter } from "../components/Section/SVGDelimiter"
import StudyProcess from "../components/Content/StudyProcess/StudyProcess"
import HealthStudy from "../components/Content/HealthStudy"
import Testimonials from "../components/Content/Testimonials/Testimonials"
import Sponsors from "../components/Content/Testimonials/Sponsors"
import Footer from "../components/Bar/footer"
import ContactComponent from "../components/Content/Contact/ContactComponent"
import { INDEX_TOOLTIPS } from "../const/MenuTooltips"
import StudySteps from "../components/Content/StudySteps/StudySteps"
import Carroussel from "../components/Carroussel/Carroussel"
import { SEO_OPTIONS_INDEX } from "../const/SEOOptions"


export default function Home({ location }) {


  const fullpageOptions = {
    navigationTooltips: INDEX_TOOLTIPS
  }



  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_INDEX}
      location={location}
    >
      <div>
        <Section isFirstSection>
          <HeaderHeroPage />
        </Section>

        <Section
          Background={`#01071a`}
        >
          <Solutions
            h2={`DATA EASING HEALTH PROGRESS`}
            h5={`Solutions for a better health`}
            CTA={`let's talk about your trial!`}
            CTALink={`/contact-us/`}
          />
        </Section>

        <Section
          Background={`#01071a`}
        >
          <EbookComponent
            EbookId={2}
            isOnEbookPage
          />
        </Section>

        <Section
          Background={`#01071a`}
        >
          <Services
            h2={`clear answers for bright decisions`}
            h5={`How can we ease your mission?`}
            CTA={`Need a Special Solution? Talk to our experts`}
          />
        </Section>

        <DelimiterSVG
          shape={SVGDelimiter.Shape.Rounded}
          fillColor={"#01071a"}
          Background={"#141B48"}
        />

        <Section
          GradientBackground={`linear-gradient(180deg,rgba(20, 27, 72, 1) 0%,rgba(8, 8, 41, 1) 100%)`}
        >
          <StudySteps
            h2={"your study within 4 steps"}
            h5={`Adaptive methods, Precise Execution`}
            CTA={`Ask A Free Tailored Study Blueprint`}
            CTALink={`/contact-us/`}

          />
        </Section>

        <DelimiterSVG
          shape={SVGDelimiter.Shape.Rounded}
          fillColor={"#080829"}
          Background={`linear-gradient(270deg,rgba(43, 77, 131, 1) 0%,rgba(1, 10, 127, 1) 100%)`}
        />

        <Section
          GradientBackground={`linear-gradient(270deg,rgba(43, 77, 131, 1) 0%,rgba(1, 10, 127, 1) 100%)`}
        >
          <StudyProcess
            h2={`busy health leaders <br />
            don't need extra anxiety`}
            h5={`We believe in a stressless health progress`}
          />
        </Section>

        <DelimiterSVG
          shape={SVGDelimiter.Shape.LineReverse}
          fillColor={`#010921`}
          Background={`linear-gradient(270deg,rgba(43, 77, 131, 1) 0%,rgba(1, 10, 127, 1) 100%)`}
        />

        <Section
          Background={`#010921`}
        >
          <HealthStudy
            h5={"Save your energy, time and fund."}
            ImgStroke={true}
          />
        </Section>

        <Section
          Background={`#010921`}
        >
          <Carroussel
            h2={'Download Our Free Guides'}
            h5={'Complex processes, simplified'}
            CTA={'ALL FREE GUIDES'}
          />
        </Section>

        <Section
          IsResponsiveGradient={false}
          GradientBackground={`radial-gradient( circle at 50% 98%, rgba(4, 19, 72, 1) 0%, rgba(1, 9, 33, 1) 18rem )`}
          Background={"rgba(1, 9, 33, 1)"}
        >
          <Testimonials />
        </Section>

        <Section
          IsResponsiveGradient={false}
          autoHeight
          GradientBackground={`radial-gradient( circle at 50% 2%, rgba(4, 19, 72, 1) 0%, rgba(1, 9, 33, 1) 18rem)`}
          Background={"rgba(1, 9, 33, 1)"}
        >
          <Sponsors />
        </Section>

        <DelimiterSVG
          shape={SVGDelimiter.Shape.Line}
          fillColor={`#010921`}
          Background={`transparent`}
        />
        <ContactComponent
          DisplayContactForm
        />

        <Footer location={location} />
      </div>
    </FullPageLayout>
  )
}



