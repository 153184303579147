import BackgroundImage from "gatsby-background-image"
import { graphql,  useStaticQuery } from "gatsby"
import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export default function HeaderHomePage() {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "main-logo-white.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      headerBackground: file(
        relativePath: { eq: "home-header-background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
       ContactBackground: file(relativePath: { eq: "contact-background.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageData = data.headerBackground.childImageSharp.fluid

  return (

    <BackgroundImage
      css={HeroPageHeaderStyle}
      style={{
        zIndex: "1",
        width: "100%",
        height: "100%"
      }}
      id="backgroundImage"
      Tag="section"
      fluid={imageData}
      backgroundColor={`#09111c`}
    >
      <Header>
        <div id="header-content-ctn" className="gutter">
          <div id="title">
            <h1>
              Your Study, <br />
              but easy.
            </h1>
            <p className="subtitle">
              The Next Revolution In Health<br />
              Studies is <b> Simplicity.</b>
            </p>
          </div>
        </div>
      </Header>
    </BackgroundImage>
  )
}


const Header  = styled.header`
height: 55vh;

  @media (max-width: 1020px) {
    height: 0;
  }
`

const HeroPageHeaderStyle = css`
  header {
    background-size: cover;
    background-position: center;
    margin-bottom: -1px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    min-height: 400px;
    margin-top:45vh;
  }

  header h1 {
    color: #f5f5f5;
    text-transform: uppercase;
    font-size: 3.75842535rem;
    font-weight: 400;
    letter-spacing: 1.44rem;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 0;
    padding-bottom:2rem;
    line-height: 1.35;
  }

  header p {
    color: #f1f1f1;
    text-align: center;
    margin: auto;
    margin-bottom: 3.4rem;
    max-width: 25rem;
    line-height: 1.4;
    padding-bottom:2rem;
  }

  h2 {
    margin-top: 0;
  }

  @media (max-width: 1023px) {
    header {
      justify-content: flex-end;
    }  

    #header-content-ctn {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: calc(100% - 3.421875rem);
    }
 
  }

  @media (max-width: 767px) {
    header h1 {
      letter-spacing: 1rem;
    }
    header p{
      margin-bottom: 13.4rem;
    }
    
  }

  @media (max-width: 479px) {
    header h1 {
      letter-spacing: 0.6rem;
    }
    header p{
      margin-bottom: 15.4rem;
    }
    
  }

  @media (max-width: 424px) {
    header h1 {
      letter-spacing: 0.3rem;
    }
    header p{
      margin-bottom: 10.4rem;
    }
  }

  @media (max-width: 374px) {
    header h1 {
      letter-spacing: 0;
    }
    header p{
      margin-bottom: 12.4rem;
    }
    
  }

  @media (max-height: 600px) {
    header h1 {
      font-size: 2.37rem;
      margin-bottom: 2rem;
    }

    header p {
      margin-bottom: 13.4rem;
    }
  }
`
