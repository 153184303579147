import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import {ContentWrapper } from "../../StyledComponents/Global"
import { SolutionCard } from "./Components/VerticalWithImage/SolutionCard"

const Solutions = ({ h2, h5, CTA }) => {
  return (
    <ContentWrapper >
      {h2 !== undefined &&
      <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
      }
      {h5 !== undefined &&
      <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
      }

      <SolutionContentCtn>
        <SolutionCard
          h4={"Clinical Studies"}
          p={`Phase II - III`}
          imgSrc={"/iconizer.png"}
          imgAlt={"CLinical studies"}
        />

        <SolutionCard
          h4={"Medical Devices"}
          p={`& Diagnostics <br />Research`}
          imgSrc={"/medical-devices.png"}
          imgAlt={"Medical Devices"}
        />

        <SolutionCard
          h4={"Real World evidence"}
          p={`Epidemiology & <br />Late Phase`}
          imgSrc={"/rwe.png" }
          imgAlt={"Real World evidence"}
        />

      </SolutionContentCtn>
      {CTA !== undefined &&

      <div>
        <Link className="cta-link" to="/contact-us/">
          {CTA}
        </Link>
        <hr />
      </div>
      }
    </ContentWrapper>
  )
}

export default Solutions

const SolutionContentCtn = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.36rem;
  flex-wrap: wrap;
  flex-direction: row;

  img {
    width: 10.3624rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 833px) {
    flex-direction: column;
    width : 100%;
    align-content: center;
  }
`