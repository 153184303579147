import styled from "@emotion/styled"

export const SolutionCtn = styled.div`
  text-align: center;
  background: #fff;
  position: relative;
  padding: 2.2rem 2.3rem 0 2.2rem;
  border-radius: 10px;
  margin-right: 7%;
  height: 23rem;
  width: 11rem;
  margin-bottom: 25px;

  &:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 833px) { 
    margin-right: 0;
  }
`

export const H4 = styled.h4`
  margin-bottom: 0;
  font-size: 20px;
`

export const P = styled.p`
  margin-top: 0.2rem;
`
