import React from "react"
import { H4, P, SolutionCtn } from "./StyledSolutionCard"

export function SolutionCard({ h4, p, imgSrc, imgAlt }) {
  return (
    <SolutionCtn>
      <img src={imgSrc} alt={imgAlt} />
      <H4 dangerouslySetInnerHTML={{ __html: h4 }} />
      <P dangerouslySetInnerHTML={{ __html: p }} />
    </SolutionCtn>
  )
}
